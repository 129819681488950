<template>
  <q-layout view="lHh Lpr lFf" >

    <CPHeader :currentTab="currentTab" @onLeftDrawer="leftDrawerOpen = !leftDrawerOpen" v-if="currentUser"/>

    <CPMenu :menuItems="menuItems" :leftDrawerOpen="leftDrawerOpen"  @onLeftDrawer="leftDrawerOpen = !leftDrawerOpen"  v-if="currentUser"/>

    <q-page-container>
      <div class="bgMyLiner likeHeader"></div>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import CPHeader from './CPHeader'
import CPMenu from './CPMenu'

export default {
  meta: {
    titleTemplate: title => `MoneyBoat | ${title}`
  },
  name: 'MyLayout',
  components: {
    CPHeader,
    CPMenu
  },
  data () {
    return {
      leftDrawerOpen: false,
      items: [
        {
          label: 'Dashboard',
          name: 'dashboard',
          icon: 'home'
        },
        {
          label: 'My Loans',
          name: 'loans',
          icon: 'cp_loans'
        },
        {
          label: 'My Cards',
          name: 'cards',
          icon: 'cp_dash_card'
        },
        {
          label: 'My Transactions',
          name: 'transactions',
          icon: 'cp_tnxs'
        },
        {
          label: 'My Profile',
          name: 'my-profile',
          icon: 'account_circle'
        },
        {
          label: 'My Paid Repayments',
          name: 'paid-repayments'
        },
        {
          label: 'My Remaining Repayments',
          name: 'remaining-repayments'
        },
        {
          label: 'My Incomes & expenditures',
          name: 'income-and-exp'
        }
      ]
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.users.currentUser
    },
    currentTab () {
      return this.items.find(i => i.name === this.$route.name)
    },
    loans () {
      return this.$store.state.loans.loans
    },
    hasOpenLoan () {
      return this.loans && this.loans.filter(l => l.isOpen()).length > 0
    },
    menuItems () {
      return this.items.filter(i => !!i.icon)
        .filter(item => {
          switch (item.name) {
            case 'loans':
              return true
            case 'cards':
              return this.hasOpenLoan
            default :
              return true
          }
        })
    }
  }
}
</script>

<style lang="scss">
  .bgMyLiner {
    background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
  }
  .bgBtnLiner .q-btn__wrapper {
    background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
  }
  .likeHeader {
    width: 100%;
    height: 70px;
    position: fixed;
    z-index: -1;
  }

  .links a {
    color: white;
    padding-left: 15px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  .q-toolbar__title span {
    cursor: pointer;
  }
  .screen--xs .my-card {
    width: 100%;
    max-width: 500px;
  }

  .screen-lg .my-card {
    width: 90%;
    max-width: 500px;
  }
</style>
