<template>
  <q-header v-if="currentUser.isExist()" class="bgMyLiner">
    <q-toolbar class="bgMyLiner" v-if="$q.screen.lt.lg">
      <q-btn
        flat
        dense
        round
        class="lt-md"
        @click="$emit('onLeftDrawer')"
        aria-label="Menu">
        <q-icon name="menu"/>
      </q-btn>

      <q-space class="gt-xs"/>

      <q-toolbar-title class="text-subtitle1 text-center">
        {{currentTab.label}}
      </q-toolbar-title>

      <q-space class="gt-xs"/>
      <q-btn flat round dense @click="navTo('my-profile')">
        <q-icon name="account_circle" size="md"/>
      </q-btn>
    </q-toolbar>

    <q-toolbar class=" cp-header gt-md bgMyLiner" inset>
      <q-item class="full-width">
        <q-item-section v-if="currentTab.name === 'dashboard'">
          <q-item >
          <q-item-section avatar>
            <q-icon name="cp_desktop_dashboard" size="70px"/>
          </q-item-section>
          <q-item-section>
            <span class="text-h5">Hello {{currentUser.firstName()}}</span>
            <span class="text-subtitle1">Welcome to your <span class="text-capitalize"> {{currentTab.name}}</span></span>
          </q-item-section>
        </q-item>
        </q-item-section>

        <q-item-section v-else>
          <span class="text-h4 text-bold text-center q-ml-xl q-pl-xl"> {{currentTab.label}}</span>
        </q-item-section>

        <q-item-section avatar>
          <q-item class="q-pr-lg">
            <q-item-section avatar class="text-bold text-subtitle1 self-center">
              <span>{{currentUser.get('name')}}</span>
            </q-item-section>
            <q-item-section>
              <q-btn flat round dense @click="navTo('my-profile')">
                <q-icon name="account_circle" size="45px"/>
              </q-btn>
            </q-item-section>
          </q-item>
        </q-item-section>
      </q-item>

    </q-toolbar>
  </q-header>
</template>

<script>
export default {
  name: 'CPHeader',
  props: {
    currentTab: {
      type: Object,
      default () {
        return {

        }
      }
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.users.currentUser
    }
  },
  methods: {
    navTo (name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name })
      }
    }
  }
}
</script>

<style scoped>
  .cp-header {
      height: 200px;
    }
</style>
